html {
  height: 100%;
}

body {
  margin: 0;
	font-family:'Palanquin', sans-serif;
	font-size:20;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

div#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  text-align: center;
}
